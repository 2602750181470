import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Zoomable = ({ image, imagelarge, alt }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <>
      <div
        className="column"
        onClick={() => setIsActive(true)}
        role="button"
        tabIndex={0}
        onKeyDown={() => setIsActive(true)}
        aria-label="Zoom"
      >
        <Img
          fixed={image.childImageSharp.fixed}
          alt={alt}
          className="zoomable"
          title={alt}
        />
      </div>
      <div className={`modal ${isActive ? "is-active" : ""}`}>
        <div
          className="modal-background"
          onClick={() => setIsActive(false)}
          tabIndex={0}
          onKeyDown={() => setIsActive(false)}
          role="button"
          aria-label="Close"
        ></div>
        <div className="modal-content">
          <div className="image">
            <Img
              fixed={imagelarge.childImageSharp.fixed}
              alt={alt}
              style={{ width: 1000, height: 750 }}
            />
          </div>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setIsActive(false)}
          onKeyDown={() => setIsActive(false)}
        ></button>
      </div>
    </>
  )
}

const GiteEn = ({ location }) => {
  const data = useStaticQuery(graphql`
    query {
      sdb: file(relativePath: { eq: "gite-sdb1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sdb2: file(relativePath: { eq: "gite-sdb2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sam1: file(relativePath: { eq: "gite-sam1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salon1: file(relativePath: { eq: "gite-salon1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine1: file(relativePath: { eq: "gite-cuisine1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine2: file(relativePath: { eq: "gite-cuisine2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrebleue: file(relativePath: { eq: "gite-chambre-bleue.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambreasia: file(relativePath: { eq: "gite-chambre-asia.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrechat: file(relativePath: { eq: "gite-chambre-chat.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vueext: file(relativePath: { eq: "gite-vueext.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin1: file(relativePath: { eq: "gite-jardin1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin2: file(relativePath: { eq: "gite-jardin2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ext: file(relativePath: { eq: "gite-ext.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chat2: file(relativePath: { eq: "gite-chat2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salleeau2e: file(relativePath: { eq: "gite-salle-eau-2e.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      elegante: file(relativePath: { eq: "gite-chambre-elegante.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura1: file(relativePath: { eq: "gite-jura1.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura2: file(relativePath: { eq: "gite-jura2.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura3: file(relativePath: { eq: "gite-jura3.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura4: file(relativePath: { eq: "gite-jura4.jpg" }) {
        childImageSharp {
          fixed(width: 300, height: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      sdblarge: file(relativePath: { eq: "gite-sdb1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sdb2large: file(relativePath: { eq: "gite-sdb2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      sam1large: file(relativePath: { eq: "gite-sam1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salon1large: file(relativePath: { eq: "gite-salon1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine1large: file(relativePath: { eq: "gite-cuisine1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      cuisine2large: file(relativePath: { eq: "gite-cuisine2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrebleuelarge: file(relativePath: { eq: "gite-chambre-bleue.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambreasialarge: file(relativePath: { eq: "gite-chambre-asia.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chambrechatlarge: file(relativePath: { eq: "gite-chambre-chat.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      vueextlarge: file(relativePath: { eq: "gite-vueext.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin1large: file(relativePath: { eq: "gite-jardin1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jardin2large: file(relativePath: { eq: "gite-jardin2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      extlarge: file(relativePath: { eq: "gite-ext.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      chat2large: file(relativePath: { eq: "gite-chat2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      salleeau2elarge: file(relativePath: { eq: "gite-salle-eau-2e.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      elegantelarge: file(relativePath: { eq: "gite-chambre-elegante.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura1large: file(relativePath: { eq: "gite-jura1.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura2large: file(relativePath: { eq: "gite-jura2.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura3large: file(relativePath: { eq: "gite-jura3.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      jura4large: file(relativePath: { eq: "gite-jura4.jpg" }) {
        childImageSharp {
          fixed(width: 1000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      gitefr: file(relativePath: { eq: "gite-de-france.png" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout lang="en" location={location}>
      <SEO
        title="Furnished gite rental"
        description="Bed and breakfast rental in the Jura"
        lang="en"
      />
      <h2 className="mt-6">Guyot cottage</h2>
      <div className="columns mt-4">
        <div className="column">
          <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/UznH7DPPuMA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div className="column">
          <p>
            Rents a furnished gîte of 150m² in a detached house on a 20 ares plot of
            land, half of which is planted with vines. It is ideally located in the
            village of <a href="http://www.montain.fr/">Montain</a>, between{" "}
            <a href="https://www.lonslesaunier.fr/">Lons-le-Saunier</a> and{" "}
            <a href="http://www.voiteur.fr/">Voiteur</a> in{" "}
            <a href="https://www.bresse-revermont.fr/decouvrir-explorer-revermont-bresse/pays-du-revermont-montagnes-jura-aintourisme/">
              Revermont
            </a>
            .
          </p>
          <h4 className="mt-5">It is composed on the first floor:</h4>
          <p>
            A dining room and a lounge area, a fully equipped kitchen, ub wc, a
            bathroom with double washbasin, a first bedroom with a motorised 160 bed
            with cupboard and chest of drawers (blue bedroom), a second bedroom
            (Asia) with a 160 bed and a piece of furniture and cupboard in an Asian
            and Zen decoration.
          </p>
          <p>
            A balcony allows you to enjoy the landscape and the tranquillity of the
            place.
          </p>
        </div>
      </div>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.sam1}
          imagelarge={data.sam1large}
          alt="Dining room"
        />
        <Zoomable
          image={data.salon1}
          imagelarge={data.salon1large}
          alt="Living room"
        />
        <Zoomable
          image={data.cuisine1}
          imagelarge={data.cuisine1large}
          alt="Kitchen"
        />
        <Zoomable
          image={data.cuisine2}
          imagelarge={data.cuisine2large}
          alt="Kitchen"
        />
        <Zoomable image={data.sdb} imagelarge={data.sdblarge} alt="Bathroom" />
        <Zoomable
          image={data.sdb2}
          imagelarge={data.sdb2large}
          alt="Bathroom"
        />
        <Zoomable
          image={data.chambrebleue}
          imagelarge={data.chambrebleuelarge}
          alt="Bleue room"
        />
        <Zoomable
          image={data.chambreasia}
          imagelarge={data.chambreasialarge}
          alt="Asia room"
        />
      </div>
      <h4 className="mt-5">On the second floor :</h4>
      <p>
        A third bedroom (Chat), more cocooning with a bed of 140, desk and
        cupboard, a fourth bedroom with a king size bed of 2m/2m and a very nice
        view of the village of{" "}
        <a href="https://fr.wikipedia.org/wiki/Lavigny_(Jura)">Lavigny</a>,
        finally a shower room equipped with toilets.
      </p>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.chat2}
          imagelarge={data.chat2large}
          alt="Cat room"
        />
        <Zoomable
          image={data.elegante}
          imagelarge={data.elegantelarge}
          alt="Chambre élégante"
        />
        <Zoomable
          image={data.chambrechat}
          imagelarge={data.chambrechatlarge}
          alt="Room king size bed"
        />
        <Zoomable
          image={data.salleeau2e}
          imagelarge={data.salleeau2elarge}
          alt="Salle d'eau au 2e étage"
        />
      </div>
      <h4 className="mt-5">Outdoor:</h4>
      <p>
        The outdoor area surrounding the house allows you to relax on the
        deckchairs at your disposal, or to eat à la plancha or to let off steam
        with outdoor games (pétanque, molky, ball).
      </p>
      <p>
        A large and spacious courtyard allows parking for several vehicles if
        you come with family or friends.
      </p>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.jardin1}
          imagelarge={data.jardin1large}
          alt="Garden furniture"
        />
        <Zoomable
          image={data.jardin2}
          imagelarge={data.jardin2large}
          alt="Weeping willow"
        />
        <Zoomable image={data.ext} imagelarge={data.extlarge} alt="Extérieur" />
        <Zoomable
          image={data.vueext}
          imagelarge={data.vueextlarge}
          alt="Exterior view"
        />
      </div>
      <h3 className="mt-6">Jura</h3>
      <p>
        The location of this gîte allows you to visit the most of the{" "}
        <a href="https://www.jura-tourism.com/">
          most beautiful tourist sites in the Jura
        </a>{" "}
        including the immediate proximity of two of the most beautiful villages
        in France (
        <a href="http://www.baumelesmessieurs.fr/">Baume-les-Messieurs</a> and{" "}
        <a href="https://www.jura-tourism.com/vivre-le-jura/visiter/villes-et-villages/chateau-chalon/">
          Château-Chalon
        </a>
        ), the vineyards and numerous lakes and bathing places (
        <a href="https://www. jura-tourism.com/vivre-le-jura/visit/villes-et-villages/clairvaux-les-lacs/">
          Clairvaux-les-Lacs
        </a>
        ,{" "}
        <a href="https://www.jura-tourism.com/vivre-le-jura/visiter/les-incontournables/lac-de-chalain/">
          Chalain
        </a>
        ,{" "}
        <a href="https://www.jura-tourism.com/vivre-le-jura/visiter/les-incontournables/lac-de-vouglans/">
          Vouglans
        </a>{" "}
        and <a href="https://www.loisirsjura.fr/">Desnes</a>). The proximity of
        the prefecture town of{" "}
        <a href="https://www.lonslesaunier.fr/">Lons-le-Saunier</a> with its
        numerous museums and facilities, cinema, swimming pool, media library,
        bowling alley and of course shops.
      </p>
      <p>
        The Jura is also a department conducive to the practice of many sporting
        activities, including hiking, which you can practice alone or
        accompanied by{" "}
        <Link to="/fr/accompagnateur-de-tourisme/">
          the owner of the premises
        </Link>{" "}
        and thus benefit from his advice and knowledge.
      </p>
      <div className="columns is-multiline mt-5 mb-5 is-vcentered has-text-centered">
        <Zoomable
          image={data.jura1}
          imagelarge={data.jura1large}
          alt="Beaume abbaye"
        />
        <Zoomable
          image={data.jura2}
          imagelarge={data.jura2large}
          alt="Tour du Meix pont de la pyle"
        />
        <Zoomable
          image={data.jura3}
          imagelarge={data.jura3large}
          alt="Château-Chalon rocher"
        />
        <Zoomable
          image={data.jura4}
          imagelarge={data.jura4large}
          alt="Cascade hérisson"
        />
      </div>
      <p>
        This gîte will give you a change of scenery but also the comfort to feel
        at home.
      </p>
      <p>
        <Link to="/fr/contact/">Contact me</Link> and{" "}
        <a href="https://www.facebook.com/Location-maison-Jura-101060245025335/">
          follow me on facebook
        </a>{" "}
        or{" "}
        <a href="https://m.me/101060245025335">contact me on messenger</a>.
      </p>
      <p>
        I look forward to welcoming you and helping you discover the Jura.
      </p>
      <p>Ludovic</p>
      <p className="is-italic mt-4 has-text-right is-size-7">
        Photo credits Albert Wolff
      </p>
    </Layout>
  )
}

export default GiteEn
